import React, { useState, useEffect, useRef, useMemo } from 'react';
import './styles/Hero.css';
import logo from './assets/quantena-logo.png';
import logoBlack from './assets/quantena-logo-black.png';
import developmentImage from './assets/development-image.png';
import riskManagementImage from './assets/risk-management-image.png';
import financialEngineeringImage from './assets/financial-engineering-image.png';

function App() {
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [transitionBlockWidth, setTransitionBlockWidth] = useState(0);
  const [lineHeight, setLineHeight] = useState(0);
  const [lineTop, setLineTop] = useState(0);
  const [animationPhase, setAnimationPhase] = useState(0);
  const [waitCounter, setWaitCounter] = useState(0);
  const firstSquareRef = useRef(null);

  const [transitionBlockLeft, setTransitionBlockLeft] = useState(0);
  const [transitionBlockOpacity, setTransitionBlockOpacity] = useState(1);
  const secondPageRef = useRef(null);

  const colors = useMemo(() => ['#8c77e7', '#b3a4ef', '#d9d1f7'], []);

  const [activeBoxColor, setActiveBoxColor] = useState(null);
  const [colorIndex, setColorIndex] = useState(0);
  const hoverTimeoutRef = useRef(null);
  const transitionTimeoutRef = useRef(null);

  const [isWhiteBackground, setIsWhiteBackground] = useState(false);
  const hoverStartTimeRef = useRef(null);

  const [isFirstHover, setIsFirstHover] = useState(true);
  const [hoveredSquare, setHoveredSquare] = useState(null);

  // Add these new state variables for the typing animation
  const [typedText, setTypedText] = useState('');
  const [currentSentence, setCurrentSentence] = useState(0);
  const [currentColor, setCurrentColor] = useState(colors[0]);
  
  const sentences = useMemo(() => [
    "Creating incorruptible financial machines.",
    "Serving people for a fairer world."
  ], []);

  const [isMobile, setIsMobile] = useState(false);
  const [activeSquare, setActiveSquare] = useState(null);
  const squareRefs = useRef([]);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    if (!isMobile) return;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = squareRefs.current.findIndex(ref => ref === entry.target);
          setActiveSquare(index);
        } else if (activeSquare === squareRefs.current.indexOf(entry.target)) {
          setActiveSquare(null);
        }
      });
    }, options);

    // Copy squareRefs.current to a variable inside the effect
    const currentSquareRefs = squareRefs.current;

    currentSquareRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      // Use the copied variable in the cleanup function
      currentSquareRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [isMobile, activeSquare]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.scrollHeight;
      const progress = scrollPosition / (fullHeight - windowHeight);
      
      const colorChangeThreshold = 0.3;
      
      setIsWhiteBackground(progress > colorChangeThreshold);

      if (secondPageRef.current && firstSquareRef.current) {
        const secondPageRect = secondPageRef.current.getBoundingClientRect();
        const firstSquareRect = firstSquareRef.current.getBoundingClientRect();
        const transitionBlockBottom = windowHeight - 20;

        if (secondPageRect.top <= transitionBlockBottom) {
          const opacity = Math.max(0, (firstSquareRect.top - transitionBlockBottom) / firstSquareRect.height);
          setTransitionBlockOpacity(opacity);
        } else {
          setTransitionBlockOpacity(1);
        }
      }
    };

    const handleResize = () => {
      if (firstSquareRef.current) {
        const rect = firstSquareRef.current.getBoundingClientRect();
        setTransitionBlockWidth(rect.width);
        setTransitionBlockLeft(rect.left);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const animateLine = () => {
      switch (animationPhase) {
        case 0:
          setLineHeight(0);
          setLineTop(0);
          setAnimationPhase(1);
          break;
        case 1:
          if (lineHeight < 100) {
            setLineHeight(prev => prev + 2);
          } else {
            setAnimationPhase(2);
            setWaitCounter(0);
          }
          break;
        case 2:
          if (waitCounter < 50) {
            setWaitCounter(prev => prev + 1);
          } else {
            setAnimationPhase(3);
          }
          break;
        case 3:
          if (lineTop < 100) {
            setLineTop(prev => prev + 2);
            setLineHeight(prev => prev - 2);
          } else {
            setAnimationPhase(0);
          }
          break;
        default:
          setAnimationPhase(0);
      }
    };

    const interval = setInterval(animateLine, 20);

    return () => clearInterval(interval);
  }, [animationPhase, lineHeight, lineTop, waitCounter]);

  // Add this new useEffect hook for the typing animation
  useEffect(() => {
    let timeout;
    const currentSentenceText = sentences[currentSentence];
    
    if (typedText.length < currentSentenceText.length) {
      timeout = setTimeout(() => {
        setTypedText(currentSentenceText.slice(0, typedText.length + 1));
      }, 100); // Adjust typing speed here
    } else {
      timeout = setTimeout(() => {
        setTypedText('');
        setCurrentSentence((prevSentence) => (prevSentence + 1) % sentences.length);
        setCurrentColor(colors[(currentSentence + 1) % colors.length]);
      }, 1000); // Wait for 1 second before clearing and switching to next sentence
    }

    return () => clearTimeout(timeout);
  }, [typedText, currentSentence, currentColor, colors, sentences]);

  const handleContactClick = (e) => {
    e.preventDefault();
    const email = 'info@quantena.tech';
    const subject = 'Contact from Website';
    const body = 'Hello Quantena,';

    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`, '_blank');

    navigator.clipboard.writeText(email).then(() => {
      alert(`Email address ${email} copied to clipboard. Please use this to contact us if your email client didn't open.`);
    }).catch(() => {
      alert(`Please email us at ${email}`);
    });
  };

  const handleBoxHover = (index) => {
    setHoveredSquare(index);
    if (isFirstHover) {
      hoverStartTimeRef.current = Date.now();
      
      hoverTimeoutRef.current = setTimeout(() => {
        const hoverDuration = Date.now() - hoverStartTimeRef.current;
        if (hoverDuration >= 250) {
          applyColorEffect();
          setIsFirstHover(false);
        }
      }, 250);
    } else {
      applyColorEffect();
    }
  };

  const applyColorEffect = () => {
    const newColor = colors[colorIndex];
    setActiveBoxColor(newColor);
    
    transitionTimeoutRef.current = setTimeout(() => {
    }, 1000);

    setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    
    // Apply the color to all boxes and the body
    document.body.style.transition = 'background-color 1s ease';
    document.body.style.backgroundColor = newColor;
    
    const squares = document.querySelectorAll('.square');
    squares.forEach(square => {
      square.style.transition = 'background-color 1s ease';
      square.style.backgroundColor = newColor;
    });
  };

  const handleBoxLeave = () => {
    setHoveredSquare(null);
    clearTimeout(hoverTimeoutRef.current);
    
    document.body.style.transition = 'background-color 1s ease';
    document.body.style.backgroundColor = 'white';

    // Reset the background color of all squares
    const squares = document.querySelectorAll('.square');
    squares.forEach(square => {
      square.style.transition = 'background-color 1s ease';
      square.style.backgroundColor = 'white';
    });

    setActiveBoxColor(null);

    setTimeout(() => {
    }, 1000);
  };

  const placeholderNames = [
    "Development",
    "Risk management",
    "Financial engineering"
  ];

  const backgroundImages = [
    developmentImage,
    riskManagementImage,
    financialEngineeringImage
  ];

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleEmailClick = (e) => {
    handleContactClick(e);
  };

  const handleBoxClick = (index) => {
    let url;
    switch(index) {
      case 0:
        url = 'https://d8x.exchange';
        break;
      case 1:
        url = 'https://github.com/D8-X/perpetual-simulation';
        break;
      case 2:
        url = 'https://medium.com/@d8x.exchange/applying-derivative-pricing-theory-to-automated-market-making-for-perpetual-futures-aba831c80ad1';
        break;
      default:
        return;
    }
    window.open(url, '_blank');
  };

  return (
    <div className="App" style={{
      backgroundColor: isWhiteBackground ? (activeBoxColor || 'white') : 'black',
      color: isWhiteBackground ? (activeBoxColor ? 'black' : 'black') : 'white',
      transition: 'background-color 1s ease, color 1s ease'
    }}>
      <header className={`header ${isWhiteBackground ? 'second-page-header' : ''}`}>
        <div 
          className="logo-container"
          onMouseEnter={() => setIsLogoHovered(true)}
          onMouseLeave={() => setIsLogoHovered(false)}
        >
          <img 
            src={isWhiteBackground ? logoBlack : logo} 
            alt="Quantena Logo" 
            className="logo-image" 
          />
          <span className={`logo-text ${isLogoHovered ? 'visible' : ''}`}>Quantena AG</span>
        </div>
        <div className="contact-section" onClick={handleContactClick}>CONTACT</div>
      </header>
      <div className="hero">
        <div className="hero-content">
          <h1>{"BUILDING INCORRUPTIBLE\nFINANCIAL MACHINES."}</h1>
        </div>
        <div className="animated-line-container">
          <div 
            className="animated-line" 
            style={{ 
              height: `${lineHeight}%`,
              top: `${lineTop}%`,
              backgroundColor: isWhiteBackground ? 'black' : 'white'
            }}
          ></div>
        </div>
        <div 
          className="transition-block" 
          style={{ 
            width: `${transitionBlockWidth}px`,
            left: `${transitionBlockLeft}px`,
            opacity: transitionBlockOpacity,
            height: '40px',
            backgroundColor: isWhiteBackground ? 'black' : 'white'
          }}
        ></div>
      </div>
      <div className="second-page" ref={secondPageRef}>
        <div className="squares-container">
          {placeholderNames.map((name, index) => (
            <div 
              key={index} 
              ref={(el) => {
                squareRefs.current[index] = el;
                if (index === 0) firstSquareRef.current = el;
              }}
              className={`square ${isMobile && activeSquare === index ? 'active' : ''}`}
              onMouseEnter={() => !isMobile && handleBoxHover(index)}
              onMouseLeave={() => !isMobile && handleBoxLeave()}
              onClick={() => handleBoxClick(index)}
            >
              <div className="square-content">
                <p className={`animated-text ${(isMobile && activeSquare === index) || (!isMobile && hoveredSquare === index) ? 'hovered' : ''}`}>
                  {name}
                </p>
              </div>
              <div className="hover-image-container">
                <div 
                  className="hover-image" 
                  style={{ 
                    backgroundImage: `url(${backgroundImages[index]})`,
                    backgroundColor: activeBoxColor || 'transparent',
                    opacity: (isMobile && activeSquare === index) || (!isMobile && hoveredSquare === index) ? 1 : 0,
                    transition: 'background-color 1s ease, opacity 1s ease'
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="third-page" style={{
        backgroundColor: 'transparent',
        color: activeBoxColor ? 'black' : 'black'
      }}>
        <div className="typing-animation-container">
          <p className="typing-text" style={{ color: currentColor }}>{typedText}</p>
        </div>
        <div className="third-page-content">
          <div className="lets-talk-section">
            <p className="lets-talk-text">Let's talk</p>
            <p 
              className="email-text" 
              onClick={handleEmailClick}
              onMouseEnter={() => {
                const randomColor = colors[Math.floor(Math.random() * colors.length)];
                document.querySelector('.email-text').style.color = randomColor;
              }}
              onMouseLeave={() => {
                document.querySelector('.email-text').style.color = 'black';
              }}
            >
              EMAIL
            </p>
          </div>
          <div 
            className="back-to-top" 
            onClick={handleBackToTop}
          >
            Back to top
          </div>
        </div>
      </div>

      <footer className="footer">
        <p>Quantena AG, Gotthardstr. 26, 6300 Zug, Switzerland</p>
      </footer>
    </div>
  );
}

export default App;